<template>
  <div class="pd-20">
    <a-page-header style="padding: 0">
      <div slot="title">
        <h1>Cias Aéreas</h1>
      </div>
      <div slot="extra">
        <a-button type="primary" ghost @click="openCreateAirline = true">
          <i class="fas fa-plane mr-5"></i> NOVA CIA
        </a-button>
      </div>
    </a-page-header>

    <a-collapse
      class="travel-filters expandable mb-10"
      activeKey="0"
      expandIconPosition="right"
    >
      <a-collapse-panel key="1">
        <template slot="header">
          <a-icon type="filter" class="mr-5" /> FILTRAR
        </template>
        <a-row class="travel-filters mt-0 mb-0" :gutter="20">
          <a-col :span="6">
            <div class="travel-input">
              <label class="filled">Nome da cia</label>
              <a-input
                placeholder="Escreva o nome, nome fantasia ou razão social"
                v-model="filters.searchTerm"
                @change="getAirlines()"
              />
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>

    <a-table
      class="travel-table"
      :columns="[
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          class: 'id',
          sorter: true,
        },
        {
          title: 'Nome Simples',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Nome Fantasia',
          dataIndex: 'trading_name',
          key: 'trading_name',
          scopedSlots: { customRender: 'trading_name' },
        },
        {
          title: 'Razão Social',
          dataIndex: 'company_name',
          key: 'company_name',
          scopedSlots: { customRender: 'company_name' },
        },
        {
          title: 'CNPJ',
          dataIndex: 'cnpj',
          key: 'cnpj',
          scopedSlots: { customRender: 'cnpj' },
        },
        {
          title: 'Data/Hora',
          dataIndex: 'created',
          key: 'created',
          sorter: true,
        },
        {
          title: '',
          key: 'action',
          align: 'right',
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ]"
      :data-source="listCias"
      :loading="loadingCias"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="tableChange"
    >
      <a slot="id" slot-scope="text"> {{ text }}</a>
      <template slot="action" slot-scope="record">
        <div class="actions-outer" style="position: relative">
          <a-tooltip placement="top" title="Editar">
            <a class="edit ml-15" @click="edit(record.id)">
              <a-icon type="edit-svg" />
            </a>
          </a-tooltip>
        </div>
      </template>
    </a-table>

    <div class="a-center mt-30" style="padding-bottom: 100px">
      <a-pagination
        show-size-changer
        :default-current="pagination.page"
        :total="pagination.total"
        @change="changePage"
        @showSizeChange="changePageSize"
      />
    </div>

    <a-drawer
      title="NOVA CIA AÉREA"
      placement="right"
      width="640px"
      :closable="true"
      :visible="openCreateAirline"
      @close="openCreateAirline = false"
    >
      <EditDefaultDataAirline
        v-if="openCreateAirline"
        @listAirlines="listAirlines"
      />
    </a-drawer>
  </div>
</template>

<script>
import EditDefaultDataAirline from "@/components/airlines/forms/EditDefaultDataAirline.vue";

export default {
  name: "ListAirlines",
  components: { EditDefaultDataAirline },
  data() {
    return {
      listCias: [],
      loadingCias: false,
      openCreateAirline: false,
      filters: {
        users: {
          list: [],
          selected: [],
        },
        period: {
          selected: [],
        },
        searchTerm: "",
        order: "desc",
        orderBy: "created",
      },
      pagination: {
        page: 1,
        perPage: 10,
        total: 0,
        totalPages: 0,
      },
    };
  },
  beforeMount() {
    this.getAirlines();
  },
  methods: {
    newCia() {},
    tableChange() {},
    listAirlines() {
      this.openCreateAirline = false;
      this.getAirlines();
    },
    edit(id) {
      this.$router.push(`edit/${id}`);
    },
    changePage(current) {
      this.pagination.page = current;
      this.getAirlines();
    },
    changePageSize(current, pageSize) {
      this.pagination.page = current;
      this.pagination.perPage = pageSize;

      this.getAirlines();
    },
    getAirlines(samePage) {
      this.loadingCias = true;

      let filters = "";

      if (this.filters.searchTerm) {
        filters += `&s=${this.filters.searchTerm}`;
      }

      filters += `&order=${this.filters.order}&order-by=${this.filters.orderBy}`;

      this.$http
        .get(
          `/airline/list?page=${this.pagination.page}&per_page=${this.pagination.perPage}${filters}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.pagination.page = 1;
          }
          this.pagination.total = data.meta.total;
          this.pagination.totalPages = data.meta.total_pages;

          this.listCias = data.data;
          this.loadingCias = false;
        })
        .catch(({ response }) => {
          response;
          this.pagination.total = response.data.meta.total;
          this.pagination.totalPages = response.data.meta.total_pages;

          this.listCias = [];
          this.loadingCias = false;
        });
    },
  },
};
</script>
