var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pd-20"},[_c('a-page-header',{staticStyle:{"padding":"0"}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('h1',[_vm._v("Cias Aéreas")])]),_vm._v(" "),_c('div',{attrs:{"slot":"extra"},slot:"extra"},[_c('a-button',{attrs:{"type":"primary","ghost":""},on:{"click":function($event){_vm.openCreateAirline = true}}},[_c('i',{staticClass:"fas fa-plane mr-5"}),_vm._v(" NOVA CIA\n      ")])],1)]),_vm._v(" "),_c('a-collapse',{staticClass:"travel-filters expandable mb-10",attrs:{"activeKey":"0","expandIconPosition":"right"}},[_c('a-collapse-panel',{key:"1"},[_c('template',{slot:"header"},[_c('a-icon',{staticClass:"mr-5",attrs:{"type":"filter"}}),_vm._v(" FILTRAR\n      ")],1),_vm._v(" "),_c('a-row',{staticClass:"travel-filters mt-0 mb-0",attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":6}},[_c('div',{staticClass:"travel-input"},[_c('label',{staticClass:"filled"},[_vm._v("Nome da cia")]),_vm._v(" "),_c('a-input',{attrs:{"placeholder":"Escreva o nome, nome fantasia ou razão social"},on:{"change":function($event){return _vm.getAirlines()}},model:{value:(_vm.filters.searchTerm),callback:function ($$v) {_vm.$set(_vm.filters, "searchTerm", $$v)},expression:"filters.searchTerm"}})],1)])],1)],2)],1),_vm._v(" "),_c('a-table',{staticClass:"travel-table",attrs:{"columns":[
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        class: 'id',
        sorter: true,
      },
      {
        title: 'Nome Simples',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Nome Fantasia',
        dataIndex: 'trading_name',
        key: 'trading_name',
        scopedSlots: { customRender: 'trading_name' },
      },
      {
        title: 'Razão Social',
        dataIndex: 'company_name',
        key: 'company_name',
        scopedSlots: { customRender: 'company_name' },
      },
      {
        title: 'CNPJ',
        dataIndex: 'cnpj',
        key: 'cnpj',
        scopedSlots: { customRender: 'cnpj' },
      },
      {
        title: 'Data/Hora',
        dataIndex: 'created',
        key: 'created',
        sorter: true,
      },
      {
        title: '',
        key: 'action',
        align: 'right',
        fixed: 'right',
        scopedSlots: { customRender: 'action' },
      },
    ],"data-source":_vm.listCias,"loading":_vm.loadingCias,"pagination":false,"scroll":{ x: 1300 }},on:{"change":_vm.tableChange},scopedSlots:_vm._u([{key:"id",fn:function(text){return _c('a',{},[_vm._v(" "+_vm._s(text))])}},{key:"action",fn:function(record){return [_c('div',{staticClass:"actions-outer",staticStyle:{"position":"relative"}},[_c('a-tooltip',{attrs:{"placement":"top","title":"Editar"}},[_c('a',{staticClass:"edit ml-15",on:{"click":function($event){return _vm.edit(record.id)}}},[_c('a-icon',{attrs:{"type":"edit-svg"}})],1)])],1)]}}])}),_vm._v(" "),_c('div',{staticClass:"a-center mt-30",staticStyle:{"padding-bottom":"100px"}},[_c('a-pagination',{attrs:{"show-size-changer":"","default-current":_vm.pagination.page,"total":_vm.pagination.total},on:{"change":_vm.changePage,"showSizeChange":_vm.changePageSize}})],1),_vm._v(" "),_c('a-drawer',{attrs:{"title":"NOVA CIA AÉREA","placement":"right","width":"640px","closable":true,"visible":_vm.openCreateAirline},on:{"close":function($event){_vm.openCreateAirline = false}}},[(_vm.openCreateAirline)?_c('EditDefaultDataAirline',{on:{"listAirlines":_vm.listAirlines}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }